<!--  -->
<template>
  <div>
    <Header />
    <Sidebar />
    <div class="main">
      <el-row :gutter="20">
        <el-col :span="12" :offset="9"
          ><div class="grid-content bg-purple top">
            <img
              src="@/assets/icon/路径 7@2x.png"
              style="weight: 32px; height: 32px"
              alt=""
            />
            <p class="title">您已取消支付，订单未生成</p>
          </div></el-col
        >
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="10"
          ><div class="grid-content bg-purple top">
            <p class="title">页面将在 3s 后返回首页</p>
          </div></el-col
        >
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="10"
          ><div class="grid-content bg-purple top">
            <button
              style="
                width: 154px;
                height: 36px;
                border-radius: 2px 2px 2px 2px;
                border: 1px solid #b60005;
                color: #b60005;
                background: #ffffff;
              "
            >
              立刻返回页面
            </button>
          </div></el-col
        >
      </el-row>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
export default {
  components: { Header, Footer, Sidebar },
  data() {
    return {};
  },
};
</script>

<style scoped>
.top > p {
  font-size: 18px;
  font-weight: bold;
  color: #141414;
}
.top > img {
  margin-right: 16px;
}
.top {
  display: flex;
  align-items: center;
  padding-top: 62px;
  text-align: center;
}
.main {
  min-height: 666px;
  padding-top: 80px;
  padding-bottom: 60px;
  border-bottom: 1px solid #eee;
  width: 1220px;
  margin: 0 auto;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
}
</style>
